<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.bathymetry") }}

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="primary" class="ml-2">
                mdi-information
              </v-icon>
            </template>
            <pre>{{ surveyDatesText }}</pre>
          </v-tooltip>
        </p>
        <p class="text-caption">
          {{ $t("configurations.hydro_information") }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <div
        class="float-right"
        v-if="hasUserPermissionToViewEditOrManage('HYDRO')"
      >
        <v-btn
          depressed
          class="mb-5 mr-1"
          color="primary"
          @click="openImportSurveyDialogAction"
          v-if="!uploading"
        >
          <v-icon left>mdi-upload</v-icon>
          {{ $t("configurations.import_bathymetry") }}
        </v-btn>
      </div>
      <Surveys />
    </div>
    <ImportSurveys :open.sync="openImportSurveyDialog" />
  </div>
</template>

<script>
import Surveys from "./Hydro/Surveys";
import ImportSurveys from "./Hydro/ImportSurveys";

export default {
  components: {
    Surveys,
    ImportSurveys,
  },
  data() {
    return {
      openImportSurveyDialog: false,
    };
  },
  computed: {
    uploading() {
      return this.$store.state.surveys.uploading;
    },
    surveyDates() {
      return this.$store.state.surveys.surveyDates;
    },
    surveyDatesText() {
      if (this.surveyDates.length > 0) {
        return this.$t("bathymetry.dates") + "\n" + this.surveyDates.join("\n");
      }

      return this.$t("bathymetry.no_bathymetry_data_available");
    },
  },
  methods: {
    openImportSurveyDialogAction() {
      this.openImportSurveyDialog = true;
    },
  },
};
</script>
