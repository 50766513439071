<template>
  <Bathymetry />
</template>

<script>
import Bathymetry from "@/components/Configurations/Bathymetry";

export default {
  components: {
    Bathymetry,
  },
};
</script>
