<template>
  <v-dialog v-model="isOpen" persistent scrollable width="900">
    <v-card tile>
      <v-progress-linear
        height="10"
        striped
        color="lime"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-upload</v-icon>{{ $t("global.import") }}</span
          >
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close" v-if="!loading">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-5">
        <v-alert v-if="!!error" color="error" text icon="mdi-close">
          {{ error }}
        </v-alert>
        <v-alert color="blue-grey" text icon="mdi-information-outline">
          {{ $t("configurations.hydro_import_grid_information") }}
        </v-alert>
        <v-alert v-if="replace" color="orange" text icon="mdi-alert-outline">
          <span v-html="$t('configurations.import_surveys_warning')" />
        </v-alert>
        <v-form ref="form" class="mt-10">
          <v-file-input
            v-model="file"
            show-size
            accept=".xyz"
            outlined
            prepend-icon
            prepend-inner-icon="mdi-paperclip"
            :error-messages="fileUploadErrorMessage"
            :rules="fileRules"
            persistent-hint
            :label="$t('configurations.xyz_file')"
          ></v-file-input>
          <v-menu
            v-model="pickerMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateText"
                readonly
                :label="$t('global.date')"
                outlined
                hide-details
                color="deep-purple accent-4"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <vc-date-picker
              v-model="date"
              mode="date"
              is-expanded
              :model-config="modelConfig"
              :max-date="new Date().toISOString()"
            >
            </vc-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-checkbox
          v-model="replace"
          :label="$t('global.replace_data')"
        ></v-checkbox>
        <v-spacer />
        <v-btn
          depressed
          color="primary"
          text
          @click="importFile"
          :disabled="loading"
        >
          {{ $t("global.import") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["open"],
  data() {
    return {
      date: new Date().toISOString().substring(0, 10),
      error: null,
      file: null,
      fileUploadErrorMessage: "",
      loading: false,
      fileRules: [(v) => !!v || this.$t("global.file_rule")],
      replace: false,
      pickerMenu: false,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
    };
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.file = null;
        this.replace = false;
      }
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    dateText: {
      get() {
        return this.date.substr(0, 10);
      },
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    importFile() {
      this.error = null;
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch("surveys/IMPORT_POINTS", {
            file: this.file,
            replace: this.replace,
            date: this.date,
          })
          .then(() => {
            this.$store
              .dispatch("surveys/GET_CELLS", this.replace)
              .then(() => {
                this.loading = false;
                this.close();
              })
              .catch(() => {
                this.loading = false;
                this.close();
              });
          })
          .catch((e) => {
            this.error = this.$t("configurations.import_file_xyz_error", {
              server_error_code: e.response.status,
              server_error_message: JSON.stringify(e.response.data),
            });
            this.loading = false;
          });
      }
    },
  },
};
</script>
