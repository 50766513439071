<template>
  <!-- Map container with a progress bar and map element -->
  <div class="map-container">
    <!-- Show progress bar when loading or uploading -->
    <v-progress-linear
      height="10"
      striped
      color="lime"
      indeterminate
      v-show="loading || uploading"
    ></v-progress-linear>
    <div ref="map" class="map" />
  </div>
</template>

<script>
// Import necessary modules and configurations
import configs from "@/helpers/configs";
import * as maptalks from "maptalks";

// Constant for the map layer name
const CELLS_LAYERNAME = "cells";

// Vue component definition
export default {
  // Define props for the component

  // Data properties for the component
  data() {
    return {
      map: null,
      loading: false,
      colors: configs.getColors(),
      surveySymbol: configs.getSurveySymbol(),
    };
  },

  // Computed properties for the component
  computed: {
    locode() {
      return configs.getLocode();
    },
    cells() {
      return this.$store.state.surveys.cells;
    },
    uploading() {
      return this.$store.state.surveys.uploading;
    },
  },

  // Watch for changes in data properties
  watch: {
    cells() {
      this.processSurvey();
    },
  },

  // Lifecycle hook called after the component has been mounted
  mounted() {
    this.loadSurvey();
    this.initMap();
  },

  // Lifecycle hook called before the component is destroyed
  beforeDestroy() {
    // Remove event listener when component is destroyed
    this.map.off("click", this.handleMapClick);
  },

  // Component methods
  methods: {
    // Initialize the map
    initMap() {
      let center;
      let bearing;
      let zoom;
      try {
        center = this.locode.coordinates;
        bearing = this.locode.bearing;
        zoom = this.locode.zoom;
      } catch (e) {
        // Log error and set default values in case of an error
        console.error(e);
        center = [-8.702487, 41.183199];
        bearing = -38;
        zoom = 15;
      }

      // Create a new map instance
      this.map = new maptalks.Map(this.$refs.map, {
        // Map configuration options
        center: center,
        zoom: zoom,
        bearing: bearing,
        seamlessZoom: true,
maxPitch: 0,
        hitDetect: false,
        layerCanvasLimitOnInteracting: -1,
        zoomControl: false,
        scaleControl: false,
        attribution: false,
        layerSwitcherControl: {
          position: "top-left",
          baseTitle: "Base Layers",
          overlayTitle: "Layers",
          excludeLayers: [],
        },
        baseLayer: new maptalks.GroupTileLayer("Base TileLayer", [
          new maptalks.TileLayer("baselayer_nv", {
            visible: true,
            urlTemplate:
              "https://basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png",
            subdomains: ["a", "b", "c", "d"],
            attribution: "OSM CARTO",
          }),
        ]),
        layers: [new maptalks.VectorLayer(CELLS_LAYERNAME, [])],
      });

      // Add a click event listener to the map
      this.map.on("click", this.handleMapClick);
    },

    // Load survey data
    loadSurvey() {
      this.loading = true;

      return new Promise((resolve, reject) => {
        // Dispatch a Vuex action to get survey cells
        this.$store
          .dispatch("surveys/GET_CELLS")
          .then(() => {
            // Resolve the promise and set loading state to false
            this.loading = false;
            resolve();
          })
          .catch((err) => {
            // Reject the promise, log the error, and set loading state to false
            this.loading = false;
            console.error(err);
            reject(err);
          });
      });
    },

    // Process survey data and update the map
    processSurvey() {
      if (this.map) {
        // Get the vector layer from the map
        let layer = this.map.getLayer(CELLS_LAYERNAME);
        layer.clear();

        // Check if survey cells exist
        if (this.cells && Object.keys(this.cells).length > 0) {
          this.cells.forEach((cell) => {
            // Convert GeoJSON to maptalks geometry
            let geometry = maptalks.GeoJSON.toGeometry(
              JSON.parse(cell.geojson)
            );

            // Set properties for the geometry
            geometry.setProperties({
              z: parseFloat(cell.value).toFixed(2),
            });

            // Set symbol for the geometry
            geometry.setSymbol(this.surveySymbol);

            // Update symbol with polygon fill color based on cell value
            geometry.updateSymbol({
              polygonFill: this.getColorByZ(parseFloat(cell.value).toFixed(2)),
            });

            // Add the geometry to the vector layer
            geometry.addTo(layer);
          });
        }
      }
    },

    // Get color based on cell value
    getColorByZ(z) {
      return this.colors[Math.round(Math.abs(z))];
    },

    // Show the survey on the map
    showSurvey() {
      if (this.map) {
        // Show the vector layer on the map
        if (this.map.getLayer(CELLS_LAYERNAME))
          this.map.getLayer(CELLS_LAYERNAME).show();
      }
    },

    // Hide the survey on the map
    hideSurvey() {
      if (this.map) {
        // Hide the vector layer on the map
        if (this.map.getLayer(CELLS_LAYERNAME))
          this.map.getLayer(CELLS_LAYERNAME).hide();
      }
    },
  },
};
</script>

<style scoped>
.map,
.map-container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 160px);
  overflow: hidden;
}

.map-container {
  position: relative;
}
</style>
<style scoped src="@/../node_modules/maptalks/dist/maptalks.css"></style>
